
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <br />

            <div class="row justify-content-md-center mt-4">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2 text-end">
                        รหัสค่าแรง:</label
                      >
                      <b-form-input
                        v-model="filter.wageCode"
                        type="search"
                        placeholder="Search..."
                        class="form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2 text-end">
                        ชื่อค่าแรง:
                      </label>
                      <b-form-input
                        v-model="filter.nameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4 float-end text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link :to="{ name: 'add-wages' }">
                      <b-button
                        type="button"
                        variant="primary"
                        class="btn btn-primary float-end"
                      >
                        <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูล
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table -->
            <div class="row justify-content-md-center mt-2">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <tableData
                :fields="fields"
                :items="rowWage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(price)="rowWage">
                  <p class="text-end">
                    {{ Number(rowWage.item.price).toLocaleString() }}
                  </p>
                </template>
                <template #cell(maxDcPer)="rowWage">
                  <p class="text-end">{{ rowWage.item.maxDcPer }}</p>
                </template>
                <template #cell(index)="rowWage" v-if="this.currentPage > 1">
                  {{ rowWage.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowWage" v-else>
                  {{ rowWage.index + 1 }}
                </template>

                <template #cell(action)="rowWage">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                          name: 'update-branchWages',
                          params: { wageId: rowWage.item.wageId },
                        }"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>

                      <a
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowWage.item.wageId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>

              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>

                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: "ข้อมูลค่าแรง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, tableData, btnComponent, Multiselect },

  data() {
    return {
      loading: undefined,

      title: "ข้อมูลค่าแรงสาขา",
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "ศูนย์บริการ",
          active: true,
        },
        {
          text: "ข้อมูลค่าแรงสาขา",
          active: true,
        },
      ],
      localDataBranchIdUser: [],
      totalPage: "",
      itemm: [],
      wageId: this.$route.params.wageId,
      selectMode: "multi",
      nameTh: "",
      branchId: [],
      selected: [],
      records: [],
      from: [],
      isLoading: "",
      id: "",
      page: "",
      total: "",
      totalRecord: "",
      rowWage: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: {
        wageCode: "",
        nameTh: "",
      },
      branchIdSearch: "",
      filterOn: [],
      sortBy: "wageId",
      sortDesc: "asc",
      selectedUsers: [],
      isSelectedAll: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "wageCode",
          label: "รหัสค่าแรง",
          sortable: true,
        },
        {
          key: "nameTh",
          label: "ชื่อค่าแรง(ไทย)",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "ชื่อค่าแรง(อังกฤษ)",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคา",
          // sortable: true,
        },
        {
          key: "maxDcPer",
          label: "ส่วนลด(%)",
          sortable: true,
        },
        {
          key: "branchName",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowWage.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowWage = this.items.length;
  },
  created() {
    this.getWage();
    this.getLocalData();
  },
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchName = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
        localDataBranchName.push(item);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchIdUser = localDataBranchName;

      // console.log(this.localDataBranch);
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranch;
      } else {
        this.localDataBranch = [this.branchIdSearch.branchId];
      }
      this.getWage();
    },
    onFiltered(filteredItems) {
      this.rowWage = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowWage) {
      this.selected = rowWage;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getWage: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/wage", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            wageCode: this.filter.wageCode,
            nameTh: this.filter.nameTh,
            branchId: this.localDataBranch,
          },
        })
        .then((response) => {
          this.rowWage = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getWage();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      for (let i = this.startIndex; i <= this.endIndex; i++) {
        // if(this.currentPage > 0){
        //    let x = ((this.currentPage * this.perPage) + i)
        //    return x;
        // }
        this.rowWage.push(i);
        return i;
      }
      this.itemm = this.rowWage;
      // console.log("000000", this.itemm);
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getWage();
    },
    deleteWage: function (rowWage) {
      this.wageData = rowWage;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/wage/delete", {
          data: {
            wageId: this.wageData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getWage();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowWage) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteWage(rowWage);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
